import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { AuthModal } from "./AuthModal";
import { useAppSelector, useAppDispatch } from "../hooks";
import { logout, selectIsAuthenticated, selectUser } from "../store/slices";
// import { logout } from "../"; // Assuming you have a logout action
import { FaUser } from "react-icons/fa";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useAppSelector(selectUser)!;
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleUserPanelClick = () => {
    navigate("/user-panel");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <StyledHeader>
      <Container>
        <LogoContainer onClick={() => navigate("/")}>
          <LogoImage src={logo} alt="Logo" />
          <LogoText>Crash Game</LogoText>
        </LogoContainer>

        <MenuToggle onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>

        <NavMenu isOpen={isMenuOpen}>
          <NavItem onClick={() => navigate("/")}>Home</NavItem>
          <NavItem onClick={() => navigate("/leaderboard")}>
            Leaderboard
          </NavItem>

          {isAuthenticated ? (
            <>
              <UserPanelButton onClick={handleUserPanelClick}>
                <FaUser /> {user.username}
              </UserPanelButton>
              <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </>
          ) : (
            <AuthButtons>
              <AuthModal />
            </AuthButtons>
          )}
        </NavMenu>
      </Container>
    </StyledHeader>
  );
};

// Styled Components
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledHeader = styled.header`
  background: linear-gradient(to right, #1a1a2e, #16213e);
  padding: 1rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4ecca3;
`;

const MenuToggle = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: #ffffff;
    margin-bottom: 4px;
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavMenu = styled.nav<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: #16213e;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    animation: ${fadeIn} 0.3s ease;
  }
`;

const NavItem = styled.div`
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #4ecca3;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const UserPanelButton = styled.button`
  display: flex;
  align-items: center;
  background: #4ecca3;
  color: #16213e;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: #45b393;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: 2px solid #4ecca3;
  color: #4ecca3;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #4ecca3;
    color: #16213e;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
`;

export { Header };

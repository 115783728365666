import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";
import { apiSlice } from "../api";

const store = configureStore({
  reducer: { rootReducer, [apiSlice.reducerPath]: apiSlice.reducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components";
// import { useSocket } from "../hooks/SocketProvider";
// import { current } from "@reduxjs/toolkit";
import { useSocket } from "../store/websockets-actions";
import { useAppSelector } from "../hooks";
import { selectUser } from "../store/slices/user-slice";
// import { getSocket } from "../store/websockets-actions";
// import socket from "../store/websockets-actions";

interface BetCardProps {
  betRefId: string;
  betNumber: number;
  onPlaceBet: (bet: { amount: number; multiplier: number }) => void;
  initialBalance: number;
}

const BetCard: React.FC<BetCardProps> = ({
  betNumber,
  betRefId,
  onPlaceBet,
  initialBalance,
}) => {
  const user = useAppSelector(selectUser);
  const [amount, setAmount] = useState<number>(0);
  const [multiplier, setMultiplier] = useState<number>(1.5);
  const [autoCashout, setAutoCashout] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("Pending");
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(1);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [potentialWin, setPotentialWin] = useState<number>(0);
  const [betId, setBetId] = useState<string>();
  const betIdRef = useRef<string | undefined>();
  const animationRef = useRef<number>();
  const { socket, isConnected } = useSocket();
  useEffect(() => {
    if (user) {
      setBalance(user.wallet_balance);
    }
  }, [user]);

  const handlePlaceBet = useCallback(() => {
    if (amount > 0 && amount <= balance) {
      onPlaceBet({ amount, multiplier });
      setStatus("Placed");
      setIsAnimating(true);
      setBalance((prevBalance) => prevBalance - amount);
      setIsWaiting(true);
      if (isConnected) {
        const socketInstance = socket();
        socketInstance.emit("placeBet", {
          betAmount: amount,
          betRefId: betRefId,
          autoCashOut: autoCashout,
          autoCashOutValue: autoCashout ? multiplier : null,
        });
        setBetId(betRefId);
        console.log("betId", betId);
      }
    } else if (amount > balance) {
      setStatus("Insufficient Funds");
      setTimeout(() => setStatus("Pending"), 2000);
    } else {
      setStatus("Error");
      setTimeout(() => setStatus("Pending"), 2000);
    }
  }, [
    amount,
    balance,
    onPlaceBet,
    multiplier,
    isConnected,
    socket,
    betRefId,
    autoCashout,
    betId,
  ]);
  const logSocketEvent = (eventName: string, data: any) => {
    console.log(`Socket event received: ${eventName}`, data);
  };
  useEffect(() => {
    betIdRef.current = betId; // Keep betIdRef updated with the latest betId
  }, [betId]);
  useEffect(() => {
    if (!isConnected) return;

    const socketInstance = socket();

    const handleBetPlaced = (data: {
      dbBetId: string;
      success: boolean;
      status: string;
      betRefId: string;
    }) => {
      console.log(data.betRefId, betId, data.dbBetId);
      if (data.betRefId === betId) {
        console.log("Executed for this", data.dbBetId);
        setStatus(data.status);
        setBetId(data.dbBetId);
        setIsWaiting(true);
      }
    };

    const handleGameStart = (data: { betId: string; status: string }) => {
      console.log("++++==+++++game start");
      if (data.betId === betId) {
        setIsWaiting(false);
        setIsPreparing(false);
        setStatus(data.status);
        setIsPlaying(true);
        setCurrentMultiplier(1);
      }
    };

    const handleGameInProgress = (data: { betId: string }) => {
      const currentBetId = betIdRef.current;
      console.log("preparation phase triggered", currentBetId);
      console.log(betId);
      if (data.betId === currentBetId) {
        console.log("game in progress", betId);
        setIsWaiting(true);
      }
    };

    const handleGameEnd = (data: {
      betId: string;
      crashMultiplier: number;
      status: string;
    }) => {
      console.log("GAME CRASHED", betId);
      if (data.betId === betId) {
        setIsPlaying(false);
        setStatus(data.status);
        setCurrentMultiplier(1);
        if (data.status === "crashed") {
          setBalance((prevBalance) => prevBalance - amount);
        }
      }
    };

    const handlePreparationPhase = (data: { betId: string }) => {
      const currentBetId = betIdRef.current;
      console.log("preparationnnnn phase triggered", currentBetId);
      if (data.betId === currentBetId) {
        setIsPreparing(true);
        setIsWaiting(false);
      }
    };

    const handleGameCashoutSuccess = (data: {
      betId: string;
      winnings: number;
      currentMultiplier: number;
      status: string;
    }) => {
      if (data.betId === betId) {
        setBalance((prevBal) => prevBal + data.winnings);
        setIsPlaying(false);
        setStatus(data.status);
      }
    };

    const handleGameTick = (data: {
      betId: string;
      currentMultiplier: number;
    }) => {
      if (data.betId === betId) {
        console.log(betId);
        setIsPlaying(true);
        setCurrentMultiplier(data.currentMultiplier);
      }
    };

    socketInstance.on("betPlaced", handleBetPlaced);
    socketInstance.on("gameStart", handleGameStart);
    socketInstance.on("gameInProgress", handleGameInProgress);
    socketInstance.on("gameEnd", handleGameEnd);
    socketInstance.on("preparationPhase", handlePreparationPhase);
    socketInstance.on("activeBetTick", handleGameTick);
    socketInstance.on("autoCashedOut", handleGameCashoutSuccess);

    return () => {
      socketInstance.off("betPlaced", handleBetPlaced);
      socketInstance.off("gameStart", handleGameStart);
      socketInstance.off("gameInProgress", handleGameInProgress);
      socketInstance.off("gameEnd", handleGameEnd);
      socketInstance.off("preparationPhase", handlePreparationPhase);
      socketInstance.off("activeBetTick", handleGameTick);
      socketInstance.off("autoCashedOut", handleGameCashoutSuccess);
    };
  }, [isConnected, socket, betId, betRefId, amount]);

  useEffect(() => {
    if (isPlaying) {
      const updatePotentialWin = () => {
        setPotentialWin(amount * currentMultiplier);
        animationRef.current = requestAnimationFrame(updatePotentialWin);
      };
      animationRef.current = requestAnimationFrame(updatePotentialWin);
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      setPotentialWin(0);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [amount, currentMultiplier, isPlaying]);

  const handleCashOut = useCallback(() => {
    if (!betId) return;
    const socketInstance = socket();
    if (isPlaying) {
      setStatus("Cashed Out");
      setIsPlaying(false);
      setBalance((prevBalance) => prevBalance + potentialWin);
      if (isConnected) {
        socketInstance.emit("cashOut", {
          betId: betId,
          currentMultiplier: currentMultiplier,
        });
      }
    }
  }, [betId, socket, isPlaying, isConnected, potentialWin, currentMultiplier]);

  return (
    <StyledBetCard>
      <CardHeader>
        <BetNumber>{betNumber}</BetNumber>
        <BetNumber>{betRefId}</BetNumber>
        <StatusIndicator status={status}>{status}</StatusIndicator>
      </CardHeader>

      <BalanceRow>
        <BalanceLabel>Balance:</BalanceLabel>
        <BalanceAmount>${balance.toFixed(2)}</BalanceAmount>
      </BalanceRow>

      {isPlaying && (
        <LiveBetInfo>
          <CurrentMultiplier>{currentMultiplier.toFixed(2)}x</CurrentMultiplier>
          <PotentialWin>Potential Win: ${potentialWin.toFixed(2)}</PotentialWin>
        </LiveBetInfo>
      )}

      {isWaiting && (
        <WaitingInfo>
          <WaitingSpinner />
          Waiting for next round...
        </WaitingInfo>
      )}
      {isPreparing && (
        <WaitingInfo>
          <WaitingSpinner />
          Prepare for the next round!
        </WaitingInfo>
      )}
      {!isPlaying && !isPreparing && !isWaiting && (
        <>
          <InputGroup>
            <Label>Bet Amount</Label>
            <StyledInput
              type="number"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              min="0"
              max={balance}
              placeholder="Enter bet amount"
              disabled={isPlaying || isWaiting}
            />
            <CurrencyIndicator>$</CurrencyIndicator>
          </InputGroup>

          {autoCashout && (
            <InputGroup>
              <Label>Payout Multiplier</Label>
              <StyledInput
                type="number"
                value={multiplier}
                onChange={(e) => setMultiplier(Number(e.target.value))}
                min="1.01"
                step="0.01"
                placeholder="Enter payout multiplier"
                disabled={isPlaying || isWaiting}
              />
              <MultiplierIndicator>x</MultiplierIndicator>
            </InputGroup>
          )}

          <AutoCashoutSwitch>
            <input
              type="checkbox"
              checked={autoCashout}
              onChange={(e) => setAutoCashout(e.target.checked)}
              disabled={isPlaying || isWaiting}
            />
            <span>Auto-Cashout</span>
          </AutoCashoutSwitch>
        </>
      )}

      <ButtonGroup>
        <PlaceBetButton
          onClick={handlePlaceBet}
          isAnimating={isAnimating}
          disabled={amount <= 0 || amount > balance || isPlaying || isWaiting}
        >
          Place Bet
        </PlaceBetButton>
        <CashOutButton onClick={handleCashOut} disabled={!isPlaying}>
          Cash Out
        </CashOutButton>
      </ButtonGroup>
    </StyledBetCard>
  );
};

// Styled components remain the same as in the previous version

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #4ecca3; }
  50% { box-shadow: 0 0 20px #4ecca3; }
  100% { box-shadow: 0 0 5px #4ecca3; }
`;

const StyledBetCard = styled.div`
  background: linear-gradient(145deg, #1e2a4a, #2a3f5f);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 1rem;
  color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BetNumber = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #4ecca3;
`;

const StatusIndicator = styled.span<{ status: string }>`
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${({ status }) =>
    status === "pending"
      ? "#ffd700"
      : status === "Placed"
      ? "#4ecca3"
      : status === "Won"
      ? "#4ecca3"
      : status === "crashed"
      ? "#ff6b6b"
      : status === "cashedOut"
      ? "#1e90ff"
      : "#ff6b6b"};
  color: ${({ status }) =>
    status === "pending" || status === "cashedOut" ? "#1a1a2e" : "#ffffff"};
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(78, 204, 163, 0.1);
  border-radius: 10px;
`;

const BalanceLabel = styled.span`
  font-size: 16px;
  color: #a0a0a0;
`;

const BalanceAmount = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #4ecca3;
`;

const LiveBetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(30, 144, 255, 0.1);
  border-radius: 10px;
  animation: ${glowAnimation} 2s infinite;
`;

const CurrentMultiplier = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #1e90ff;
`;

const PotentialWin = styled.span`
  font-size: 18px;
  color: #4ecca3;
`;

const WaitingInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 10px;
  font-size: 16px;
  color: #ffd700;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const WaitingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #ffd700;
  border-top: 2px solid transparent;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${spinAnimation} 1s linear infinite;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #a0a0a0;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #16213e;
  color: #ffffff;
  font-size: 16px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #4ecca3;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CurrencyIndicator = styled.span`
  position: absolute;
  right: 10px;
  top: 35px;
  color: #4ecca3;
`;

const MultiplierIndicator = styled(CurrencyIndicator)``;

const AutoCashoutSwitch = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;

  input {
    appearance: none;
    width: 50px;
    height: 26px;
    background-color: #16213e;
    border-radius: 13px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;

    &:before {
      content: "";
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      background-color: #a0a0a0;
      transition: all 0.3s ease;
    }

    &:checked {
      background-color: #4ecca3;

      &:before {
        transform: translateX(24px);
        background-color: #ffffff;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    margin-left: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BaseButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PlaceBetButton = styled(BaseButton)<{ isAnimating: boolean }>`
  background-color: #4ecca3;
  color: #1a1a2e;
  flex-grow: 1;
  margin-right: 10px;

  &:hover:not(:disabled) {
    background-color: #45b393;
  }

  animation: ${({ isAnimating }) => (isAnimating ? glowAnimation : "none")} 1s
    ease-in-out;
`;

const CashOutButton = styled(BaseButton)`
  background-color: #1e90ff;
  color: #ffffff;
  flex-grow: 1;

  &:hover:not(:disabled) {
    background-color: #1c86ee;
  }
`;

export { BetCard };

import React from "react";
import styled, { keyframes } from "styled-components";

// Define the animation for the loading dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Styled components for each dot and the container
const LoadingDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #007bff; /* Customize color as needed */
  border-radius: 50%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

// The loading dots component
export const LoadingDots = () => {
  return (
    <LoadingDotsContainer>
      <Dot />
      <Dot />
      <Dot />
    </LoadingDotsContainer>
  );
};

import React, { useState, useEffect, useRef, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { motion, AnimatePresence } from "framer-motion";
import { useSocket } from "../store/websockets-actions";
import { NextRoundOverlay } from "./NextRound";
// import { useSocket } from "../hooks/SocketProvider";
// import NextRoundOverlay from "./NextRound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MultiplierDisplay: React.FC = () => {
  const [multiplier, setMultiplier] = useState<number[]>([]);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [isCrashed, setIsCrashed] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const { socket, isConnected } = useSocket();

  const animationRef = useRef<number>();
  const wsRef = useRef<WebSocket | null>(null);
  // const socket = getSocket();

  const maxDataPoints = 100; // Limit the number of data points to improve performance
  useEffect(() => {
    console.log("Socket connection status:", isConnected);

    if (!isConnected) {
      console.log("Socket is not connected. Waiting for connection...");
      return;
    }

    const socketInstance = socket();

    if (!socketInstance) {
      console.error("Failed to get socket instance");
      return;
    }

    console.log("Setting up socket event listeners");

    const onGameStart = () => {
      console.log("Game start event received");
      handleCountdownComplete();
      setMultiplier([]);
      setCurrentMultiplier(1);
      setIsCrashed(false);
    };

    const onGameTick = (data: { currentMultiplier: number }) => {
      console.log("Game tick m event received", data);
      if (isCrashed) return;

      const newMultiplier = data.currentMultiplier;
      setCurrentMultiplier(newMultiplier);
      setMultiplier((prev) => [...prev.slice(-maxDataPoints), newMultiplier]);
    };

    const onGameEnd = (data: { crashMultiplier: number }) => {
      console.log("Game end event received", data);
      setIsCrashed(true);
      setCurrentMultiplier(data.crashMultiplier);
      setTimeout(() => {
        setIsCrashed(false);
      }, 3000);
    };

    const onPreparationPhase = () => {
      console.log("Preparation phase event received", data);
      setShowOverlay(true);
      // Remove empty setInterval
    };

    socketInstance.on("globalGameStart", onGameStart);
    socketInstance.on("gameTick", onGameTick);
    socketInstance.on("gameEnd", onGameEnd);
    socketInstance.on("globalPreparationPhase", onPreparationPhase);

    return () => {
      console.log("Cleaning up socket event listeners");
      socketInstance.off("globalGameStart", onGameStart);
      socketInstance.off("gameTick", onGameTick);
      socketInstance.off("gameEnd", onGameEnd);
      socketInstance.off("globalPreparationPhase", onPreparationPhase);
    };
  }, [isConnected, isCrashed, socket]);

  const restartGame = () => {
    // startNewRound(); // Emit startNewRound to server
  };
  useEffect(() => {
    if (isConnected) {
      const socketInstance = socket();
      socketInstance.on("preparationPhase", () => {
        console.log("preparation riggere");
      });
    }
  });
  const data = useMemo(
    () => ({
      labels: multiplier.map((_, index) => index.toString()),
      datasets: [
        {
          label: "Multiplier",
          data: multiplier,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 2,
          pointRadius: 0,
          cubicInterpolationMode: "monotone" as "monotone", // Explicitly cast
        },
      ],
    }),
    [multiplier]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: true,
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const handleCountdownComplete = () => {
    setShowOverlay(false);
    // Start the new round here
  };
  return (
    <MultiplierContainer>
      <GraphContainer>
        <NextRoundOverlay
          isVisible={showOverlay}
          countdown={countdown}
          onCountdownComplete={handleCountdownComplete}
        />
        <Line data={data} options={options} />
        <AnimatePresence>
          {!isCrashed && (
            <CurrentMultiplier
              key="multiplier"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              {currentMultiplier.toFixed(2)}x
            </CurrentMultiplier>
          )}
          {isCrashed && (
            <CrashedOverlay
              key="crashed"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <CrashedText>
                CRASHED AT {currentMultiplier.toFixed(2)}x
              </CrashedText>
              {/* <RestartButton onClick={restartGame}>Restart</RestartButton> */}
            </CrashedOverlay>
          )}
        </AnimatePresence>
      </GraphContainer>
    </MultiplierContainer>
  );
};

// Styled Components
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const MultiplierContainer = styled.div`
  background: linear-gradient(to bottom right, #1a1a2e, #16213e);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
`;

const GraphContainer = styled.div`
  position: relative;
  height: 300px;
`;

const CurrentMultiplier = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: #4ecca3;
  text-shadow: 0 0 10px rgba(78, 204, 163, 0.5);
  animation: ${pulse} 1s infinite;
`;

const CrashedOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 107, 107, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
`;

const CrashedText = styled.h2`
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const RestartButton = styled.button`
  background: #4ecca3;
  color: #16213e;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #45b393;
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;
export { MultiplierDisplay };

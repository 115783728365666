// config.base.js
export const BASE_CONFIG = {
  API: {
    TIMEOUT: 10000,
    RETRY_ATTEMPTS: 3,
  },
  AUTH: {
    TOKEN_KEY: "authToken",
    REFRESH_TOKEN_KEY: "refreshToken",
    SESSION_TIMEOUT: 3600,
  },
  UI: {
    ITEMS_PER_PAGE: 10,
    DEFAULT_LANGUAGE: "en",
  },
  LOGGING: {
    ENABLE_CONSOLE_LOGGING: true,
    LOG_LEVEL: "info",
  },
  FEATURES: {
    ENABLE_DARK_MODE: true,
  },
};

export const DEV_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "development",
  API: {
    ...BASE_CONFIG.API,
    BASE_URL: "http://crashbo.codeshop.co.ke",
  },
  LOGGING: {
    ...BASE_CONFIG.LOGGING,
    LOG_LEVEL: "debug",
  },
  FEATURES: {
    ...BASE_CONFIG.FEATURES,
    ENABLE_BETA_FEATURES: true,
  },
};

export const STAGING_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "staging",
  API: {
    ...BASE_CONFIG.API,
    BASE_URL: "http://crashbo.codeshop.co.ke",
  },
  LOGGING: {
    ...BASE_CONFIG.LOGGING,
    LOG_LEVEL: "info",
  },
  FEATURES: {
    ...BASE_CONFIG.FEATURES,
    ENABLE_ANALYTICS: true,
  },
};
export const PROD_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "production",
  API: {
    ...BASE_CONFIG.API,
    BASE_URL: "http://crashbo.codeshop.co.ke",
  },
  LOGGING: {
    ENABLE_CONSOLE_LOGGING: false,
    LOG_LEVEL: "error",
    ENABLE_REMOTE_LOGGING: true,
  },
  FEATURES: {
    ENABLE_DARK_MODE: true,
    ENABLE_ANALYTICS: true,
  },
};

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

interface AnimatedObject {
  id: number;
  shape: "circle" | "square" | "triangle" | "star";
  x: number;
  y: number;
  size: number;
  rotation: number;
  color: string;
}

interface NextRoundOverlayProps {
  isVisible: boolean;
  countdown: number;
  onCountdownComplete: () => void;
}

const NextRoundOverlay: React.FC<NextRoundOverlayProps> = ({
  isVisible,
  countdown,
  onCountdownComplete,
}) => {
  const [animatedObjects, setAnimatedObjects] = useState<AnimatedObject[]>([]);

  useEffect(() => {
    if (isVisible) {
      setAnimatedObjects(generateAnimatedObjects());
    } else {
      setAnimatedObjects([]);
    }
  }, [isVisible]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isVisible && countdown > 0) {
      timer = setTimeout(() => {
        if (countdown === 1) {
          onCountdownComplete();
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isVisible, countdown, onCountdownComplete]);

  const generateAnimatedObjects = (): AnimatedObject[] => {
    const objects: AnimatedObject[] = [];
    const shapes: ("circle" | "square" | "triangle" | "star")[] = [
      "circle",
      "square",
      "triangle",
      "star",
    ];
    const colors: string[] = ["#ff6b6b", "#4ecca3", "#feca57", "#54a0ff"];

    for (let i = 0; i < 20; i++) {
      objects.push({
        id: i,
        shape: shapes[Math.floor(Math.random() * shapes.length)],
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 40 + 20,
        rotation: Math.random() * 360,
        color: colors[Math.floor(Math.random() * colors.length)],
      });
    }
    return objects;
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <OverlayContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* <CountdownText>{countdown}</CountdownText> */}
          <NextRoundText>Next Round Starting Soon!</NextRoundText>
          {animatedObjects.map((obj) => (
            <AnimatedObject
              key={obj.id}
              style={{
                left: `${obj.x}%`,
                top: `${obj.y}%`,
                width: `${obj.size}px`,
                height: `${obj.size}px`,
                backgroundColor: obj.color,
              }}
              animate={{
                rotate: [0, obj.rotation],
                scale: [1, 1.2, 1],
                y: [0, -20, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
              shape={obj.shape}
            />
          ))}
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
};

const OverlayContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 26, 46, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const CountdownText = styled.div`
  font-size: 10rem;
  font-weight: bold;
  color: #4ecca3;
  text-shadow: 0 0 20px rgba(78, 204, 163, 0.7);
  animation: ${float} 2s ease-in-out infinite;
`;

const NextRoundText = styled.div`
  font-size: 2.5rem;
  color: #ffffff;
  margin-top: 20px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
`;

const getShapePath = (shape: string): string => {
  switch (shape) {
    case "triangle":
      return "polygon(50% 0%, 0% 100%, 100% 100%)";
    case "star":
      return "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)";
    default:
      return "none";
  }
};

const AnimatedObject = styled(motion.div)<{ shape: string }>`
  position: absolute;
  border-radius: ${({ shape }) => (shape === "circle" ? "50%" : "0")};
  clip-path: ${({ shape }) => getShapePath(shape)};
  opacity: 0.7;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
`;

export { NextRoundOverlay };

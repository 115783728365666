import React, { ReactNode } from "react";

interface GenericContainerProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

const GenericContainer: React.FC<GenericContainerProps> = ({
  title,
  children,
  className = "",
}) => {
  return (
    <div className={`generic-container ${className}`}>
      {/* {title && <h5 className="container-title">{title}</h5>} */}
      <div className="container-content">{children}</div>
    </div>
  );
};

export { GenericContainer };

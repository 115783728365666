// src/components/Game.tsx

import React, { useEffect, useState } from "react";
import { MultiplierDisplay } from "./MultiplierDisplay";

import { BetPlacement } from "./BetPlacement";
import { GenericContainer } from "./container";
import { RecentWinners } from "./Winner";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch } from "../hooks";

// import { initializeWebSocket } from "../store/websockets-actions";
// Define the types for the component's state

const Game: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="game-container">
      <GenericContainer title="Multiplier">
        <Row>
          <Col md={8} style={{ padding: 12, margin: 0, maxHeight: "100vh" }}>
            <MultiplierDisplay />
            <BetPlacement />
          </Col>
          <Col md={4}>
            <RecentWinners />
          </Col>
        </Row>
      </GenericContainer>
    </div>
  );
};

export { Game };

import React, { useState } from "react";
import styled from "styled-components";
import placeholder from "../assets/images/user.png";
import {
  Tabs,
  Tab,
  Card,
  Button,
  Form,
  Table,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaUser, FaWallet, FaHistory, FaExchangeAlt } from "react-icons/fa";
import {
  useDepositMutation,
  useGetBetHistoryQuery,
  useGetTransactionHistoryQuery,
  useWithdrawMutation,
} from "../api";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectUser } from "../store/slices";
import { BetAttributes, SnackBarMode } from "../types";
import { formatDateToReadable } from "../utils";
import { TransactionModal } from "../components/TransactionModal";
import { error } from "console";
import { updateUserWallet } from "../store/slices/user-slice";
import { LoadingDots } from "../components/LoadingDots";
import { Snackbar } from "../components/SnackBar";

// Styled components
const StyledCard = styled(Card)`
  max-width: 900px;
  margin: 2rem auto;
`;

const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const BalanceText = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
`;

export const UserPanel: React.FC = () => {
  const user = useAppSelector(selectUser)!;
  // const [balance, setBalance] = useState<number>(user.wallet_balance);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [deposit, { isLoading: depositLoading }] = useDepositMutation();
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  const [withdraw, { isLoading: withdrawalLoading }] = useWithdrawMutation();
  const dispatch = useAppDispatch();
  const { data: betHistory, isLoading: betHistoryLoading } =
    useGetBetHistoryQuery(user.id);
  const { data: transactionHistory, isLoading: transactionHistoryLoading } =
    useGetTransactionHistoryQuery(user.id);
  const handleDeposit = async ({
    amount,
    mpesaNumber,
  }: {
    amount: number;
    mpesaNumber: string;
  }) => {
    try {
      const response = await deposit({
        amount,
        mpesa_number: mpesaNumber,
        userId: user.id.toString(),
      });

      if ("data" in response && response.data !== undefined) {
        dispatch(updateUserWallet(response.data.wallet_balance));
        setSnackbar({
          show: true,
          message: response.data.message,
          type: "success",
        });
        // Optionally, update transaction history here or refetch it
      }
      if ("error" in response) {
        setSnackbar({ show: true, message: "Payment failed!", type: "error" });
        // console.log("Withdrawal failed:", error);
      }
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Error during deposit!",
        type: "error",
      });
      // console.log("Error during deposit:", error);
    }
  };

  const handleWithdraw = async ({
    amount,
    mpesaNumber,
  }: {
    amount: number;
    mpesaNumber: string;
  }) => {
    try {
      const response = await withdraw({
        amount,
        mpesa_number: mpesaNumber,
        userId: user.id,
      });

      if ("data" in response && response.data !== undefined) {
        dispatch(updateUserWallet(response.data.wallet_balance));
        setSnackbar({
          show: true,
          message: response.data.message,
          type: "success",
        });
        // Optionally, update transaction history here or refetch it
      }
      if ("error" in response) {
        setSnackbar({
          show: true,
          message: "Withdrawal failed!",
          type: "error",
        });
        // console.log("Withdrawal failed:", error);
      }
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Error during withdrawal!",
        type: "error",
      });
      // console.log("Error during withdrawal:", error);
    }
  };
  if (depositLoading || withdrawalLoading) {
    return <LoadingDots />;
  }
  if (betHistoryLoading || transactionHistoryLoading) {
    return <Spinner animation="border" />;
  }
  return (
    <StyledCard>
      {snackbar.show && (
        <Snackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar({ ...snackbar, show: false })}
        />
      )}

      <Card.Header>
        <h2>User Dashboard</h2>
      </Card.Header>
      <Card.Body>
        <Tabs defaultActiveKey="profile" id="user-panel-tabs" className="mb-3">
          <Tab
            eventKey="profile"
            title={
              <>
                <FaUser /> Profile
              </>
            }
          >
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center mb-4">
                  <AvatarImage
                    // src="/api/placeholder/100/100"
                    src={placeholder}
                    alt="User avatar"
                    className="me-3"
                  />
                  <div>
                    <h3>Username</h3>
                    <p className="text-muted">{user.username}</p>
                  </div>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter first name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter last name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" placeholder="Enter phone number" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Update Profile
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="wallet"
            title={
              <>
                <FaWallet /> Wallet
              </>
            }
          >
            <Card>
              <Card.Body className="text-center">
                <h3>Current Balance</h3>
                <BalanceText>${user.wallet_balance.toFixed(2)}</BalanceText>
                <div className="mt-4">
                  <Button
                    variant="success"
                    className="me-2"
                    onClick={() => setShowDepositModal(true)}
                  >
                    Deposit
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowWithdrawModal(true)}
                  >
                    Withdraw
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="betHistory"
            title={
              <>
                <FaHistory /> Bet History
              </>
            }
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Multiplier</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {betHistory === undefined || betHistory.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  betHistory.map((bet) => (
                    <tr key={bet.id}>
                      <td>{formatDateToReadable(new Date(bet.createdAt))}</td>
                      <td>{bet.status}</td>
                      <td>x{bet.crashMultiplier}</td>
                      <td
                        className={
                          bet.status === "cashedOut"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        ${bet.amount}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Tab>

          <Tab
            eventKey="transactionHistory"
            title={
              <>
                <FaExchangeAlt /> Transactions
              </>
            }
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactionHistory === undefined ||
                transactionHistory.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  transactionHistory.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>
                        {formatDateToReadable(
                          new Date(transaction.created_at!)
                        )}
                      </td>
                      <td>{transaction.status}</td>
                      <td>{transaction.transaction_type}</td>
                      <td
                        className={
                          transaction.amount > 0
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        ${Math.abs(transaction.amount)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
        {/* Deposit Modal */}
        <TransactionModal
          show={showDepositModal}
          handleClose={() => setShowDepositModal(false)}
          transactionType="deposit"
          onSubmit={handleDeposit}
        />

        {/* Withdraw Modal */}
        <TransactionModal
          show={showWithdrawModal}
          handleClose={() => setShowWithdrawModal(false)}
          transactionType="withdraw"
          onSubmit={handleWithdraw}
        />
      </Card.Body>
    </StyledCard>
  );
};

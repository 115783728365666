import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

interface TransactionModalProps {
  show: boolean;
  handleClose: () => void;
  transactionType: "deposit" | "withdraw";
  onSubmit: (data: { amount: number; mpesaNumber: string }) => void;
}

export const TransactionModal: React.FC<TransactionModalProps> = ({
  show,
  handleClose,
  transactionType,
  onSubmit,
}) => {
  const [amount, setAmount] = useState<string>("");
  const [mpesaNumber, setMpesaNumber] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({ amount: parseFloat(amount), mpesaNumber });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {transactionType === "deposit" ? "Deposit" : "Withdraw"} Funds
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAmount(e.target.value)
              }
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>M-Pesa Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter M-Pesa number"
              value={mpesaNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMpesaNumber(e.target.value)
              }
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Confirm {transactionType === "deposit" ? "Deposit" : "Withdrawal"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

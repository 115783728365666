// src/store/webSocketSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WebSocketState {
  socket: WebSocket | null;
  connected: boolean;
  gameData: any;
  bets: { [userId: string]: number };
  cashouts: { [userId: string]: number };
}

const initialState: WebSocketState = {
  socket: null,
  connected: false,
  gameData: null,
  bets: {},
  cashouts: {},
};

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    connect(state, action: PayloadAction<WebSocket>) {
      state.socket = action.payload;
      state.connected = true;
    },
    disconnect(state) {
      state.socket?.close();
      state.socket = null;
      state.connected = false;
    },
    setGameData(state, action: PayloadAction<any>) {
      state.gameData = action.payload;
    },
    placeBet(state, action: PayloadAction<{ userId: string; amount: number }>) {
      state.bets[action.payload.userId] = action.payload.amount;
    },
    cashOut(
      state,
      action: PayloadAction<{ userId: string; multiplier: number }>
    ) {
      state.cashouts[action.payload.userId] = action.payload.multiplier;
    },
  },
});

export const { connect, disconnect, setGameData, placeBet, cashOut } =
  webSocketSlice.actions;

export default webSocketSlice.reducer;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface SnackbarProps {
  message: string;
  type?: "success" | "error";
  duration?: number;
  onClose: () => void;
}

// Styled container for the snackbar
const SnackbarContainer = styled.div<{
  type: "success" | "error";
  show: boolean;
}>`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) =>
    props.type === "success" ? "#28a745" : "#dc3545"};
  color: white;
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  max-width: 600px;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.5s ease, top 0.5s ease;
`;

// Styled close button
const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
`;

export const Snackbar: React.FC<SnackbarProps> = ({
  message,
  type = "success",
  duration = 5000,
  onClose,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <SnackbarContainer type={type} show={show}>
      <span>{message}</span>
      <CloseButton
        onClick={() => {
          setShow(false);
          onClose();
        }}
      >
        ×
      </CloseButton>
    </SnackbarContainer>
  );
};

import { useState, useEffect, useCallback } from "react";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "../hooks"; // Adjust this import based on your project structure

interface ServerToClientEvents {
  // Define your server-to-client events here
  gameStart: (data: { betId: string; status: string }) => void;
  gameInProgress: (data: { betId: string }) => void;
  preparationPhase: (data: { betId: string }) => void;
  gameTick: (data: { betId: string; currentMultiplier: number }) => void;
  activeBetTick: (data: { betId: string; currentMultiplier: number }) => void;
  betPlaced: (data: {
    dbBetId: string;
    success: true;
    status: string;
    betRefId: string;
  }) => void;
  globalGameStart: () => void;
  globalPreparationPhase: () => void;
  gameEnd: (data: {
    betId: string;
    crashMultiplier: number;
    status: string;
  }) => void;
  autoCashedOut: (data: {
    betId: string;
    winnings: number;
    currentMultiplier: number;
    status: string;
  }) => void;
  cashOutSuccess: (data: {
    userId: string;
    winnings: number;
    currentMultiplier: number;
  }) => void;
}

interface ClientToServerEvents {
  // Define your client-to-server events here
  placeBet: (data: {
    betAmount: number;
    autoCashOut: boolean;
    autoCashOutValue: number | null;
    betRefId: string;
  }) => void;
  cashOut: (data: { betId: string; currentMultiplier: number }) => void;
  startNewRound: () => void;
}

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const user = useAppSelector((state) => state.rootReducer.users.user); // Adjust this based on your Redux store structure

  const initializeSocket = useCallback(() => {
    if (user && user.id) {
      const newSocket = io("http://crashbo.codeshop.co.ke", {
        auth: { userId: user.id },
        reconnectionAttempts: 5,
        timeout: 10000,
      });

      newSocket.on("connect", () => {
        console.log("Socket connected");
        setIsConnected(true);
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
        setIsConnected(false);
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [user]);

  useEffect(() => {
    const cleanup = initializeSocket();
    return cleanup;
  }, [initializeSocket]);

  const getSocket = useCallback(() => {
    if (!socket) {
      throw new Error("Socket not initialized. Make sure you are logged in.");
    }
    return socket;
  }, [socket]);

  const placeBet = useCallback(
    (data: {
      betAmount: number;
      autoCashOut: boolean;
      autoCashOutValue: number | null;
      betRefId: string;
    }) => {
      getSocket().emit("placeBet", data);
    },
    [getSocket]
  );

  const cashOut = useCallback(
    (data: { betId: string; currentMultiplier: number }) => {
      getSocket().emit("cashOut", data);
    },
    [getSocket]
  );

  const startNewRound = useCallback(() => {
    getSocket().emit("startNewRound");
  }, [getSocket]);

  return {
    socket: getSocket,
    isConnected,
    placeBet,
    cashOut,
    startNewRound,
  };
};

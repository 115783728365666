// Register.tsx
import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useRegisterUserMutation } from "../api";

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RegisterInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

const RegisterButton = styled.button`
  background: #4ecca3;
  color: #16213e;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
`;

const Register: React.FC<{ handleSwitch: () => void }> = ({ handleSwitch }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const [isLogin, setIsLogin] = useState(true);

  const handleRegister = async () => {
    const initialWallet = 0;
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const userData = {
        username,
        phone_number: phone,
        password,
        wallet_balance: initialWallet,
      };

      const response = await registerUser(userData).unwrap();
      console.log("User registered successfully:", response);
      handleSwitch();

      // You can add logic to navigate to another page or show a success message here
    } catch (error) {
      console.error("Registration failed:", error);
      alert("Registration failed. Please try again.");
    }
  };

  return (
    <RegisterContainer>
      <RegisterInput
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <PhoneInput
        country={"ke"} // Set the default country
        value={phone}
        onChange={setPhone}
        containerStyle={{ marginBottom: "1rem" }}
        inputStyle={{
          width: "100%",
          padding: "0.5rem",
          borderRadius: "4px",
          border: "none",
        }}
      />
      <RegisterInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      {/* <RegisterInput
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      /> */}
      <RegisterInput
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <RegisterInput
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirm Password"
      />
      <RegisterButton onClick={handleRegister} disabled={isLoading}>
        {isLoading ? "Registering..." : "Register"}
      </RegisterButton>
      <p>
        Already have an account?{" "}
        <span
          onClick={handleSwitch}
          style={{ cursor: "pointer", color: "#4ecca3" }}
        >
          Login
        </span>
      </p>
    </RegisterContainer>
  );
};

export { Register };

import React, { useState, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { useGetAllBetsQuery } from "../api";
import { useAppSelector } from "../hooks";
import { selectUser } from "../store/slices";
import { AlertTriangle } from "lucide-react";
import { formatDateToReadable } from "../utils";

interface Winner {
  id: string;
  username: string;
  betAmount: number;
  cashoutMultiplier: number;
  winAmount: number;
  timestamp: string;
  isCurrentUser: boolean;
}

// const dummyWinners: Winner[] = [
//   {
//     id: "1",
//     username: "LuckyPlayer123",
//     betAmount: 50,
//     cashoutMultiplier: 2.5,
//     winAmount: 125,
//     timestamp: "2024-08-23T14:30:00Z",
//     isCurrentUser: false,
//   },
//   {
//     id: "2",
//     username: "RiskTaker777",
//     betAmount: 100,
//     cashoutMultiplier: 3.2,
//     winAmount: 320,
//     timestamp: "2024-08-23T14:29:30Z",
//     isCurrentUser: true,
//   },
//   {
//     id: "3",
//     username: "RiskTaker777",
//     betAmount: 100,
//     cashoutMultiplier: 3.2,
//     winAmount: 320,
//     timestamp: "2024-08-23T14:29:30Z",
//     isCurrentUser: true,
//   },
//   {
//     id: "4",
//     username: "RiskTaker777",
//     betAmount: 100,
//     cashoutMultiplier: 3.2,
//     winAmount: 320,
//     timestamp: "2024-08-23T14:29:30Z",
//     isCurrentUser: true,
//   },
//   {
//     id: "5",
//     username: "RiskTaker777",
//     betAmount: 100,
//     cashoutMultiplier: 3.2,
//     winAmount: 320,
//     timestamp: "2024-08-23T14:29:30Z",
//     isCurrentUser: true,
//   },
//   {
//     id: "6",
//     username: "RiskTaker777",
//     betAmount: 100,
//     cashoutMultiplier: 3.2,
//     winAmount: 320,
//     timestamp: "2024-08-23T14:29:30Z",
//     isCurrentUser: true,
//   },
//   // ... add more dummy data here
// ];

type FilterType = "all" | "myBets" | "highest";

const RecentWinners: React.FC = () => {
  const user = useAppSelector(selectUser)!;
  const [filter, setFilter] = useState<FilterType>("all");
  const { data: betHistory, isLoading, error } = useGetAllBetsQuery();

  const filteredWinners = useMemo(() => {
    if (!betHistory) return [];

    const winners = betHistory.map((bet) => ({
      id: bet.id,
      userId: bet.userId,
      username: bet.user.username,
      betAmount: bet.amount,
      cashoutMultiplier: bet.crashMultiplier,
      winAmount: bet.amount * bet.crashMultiplier,
      timestamp: bet.createdAt,
    }));
    console.log(typeof user?.id);
    console.log(
      winners.filter((winner) => winner.userId === user?.id.toString())
    );
    switch (filter) {
      case "myBets":
        return winners.filter(
          (winner) => winner.userId === user?.id.toString()
        );
      case "highest":
        return [...winners].sort((a, b) => b.winAmount - a.winAmount);
      default:
        return winners;
    }
  }, [filter, betHistory, user]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading recent winners...</LoadingText>
      </LoadingContainer>
    );
  }
  if (error) {
    return (
      <ErrorContainer>
        <AlertTriangle size={48} color="#ff6b6b" />
        <ErrorText>Oops! We couldn't load the recent winners.</ErrorText>
        <ErrorSubText>
          Please try again later or contact support if the problem persists.
        </ErrorSubText>
      </ErrorContainer>
    );
  }
  return (
    <WinnersContainer>
      <Title>Recent Winners 🏆</Title>
      <FilterContainer>
        <FilterButton
          active={filter === "all"}
          onClick={() => setFilter("all")}
        >
          All Bets
        </FilterButton>
        <FilterButton
          active={filter === "myBets"}
          onClick={() => setFilter("myBets")}
        >
          My Bets
        </FilterButton>
        <FilterButton
          active={filter === "highest"}
          onClick={() => setFilter("highest")}
        >
          Highest Wins
        </FilterButton>
      </FilterContainer>
      <WinnersList>
        {filteredWinners.map((winner) => (
          <WinnerCard
            key={winner.id}
            isCurrentUser={winner.userId === user?.id}
          >
            <Username>{winner.username}</Username>
            <WinInfo>
              <BetAmount>${winner.betAmount.toFixed(2)}</BetAmount>
              <Multiplier>x{winner.cashoutMultiplier.toFixed(2)}</Multiplier>
            </WinInfo>
            <WinAmount>${winner.winAmount.toFixed(2)}</WinAmount>
            <Timestamp>
              {formatDateToReadable(new Date(winner.timestamp))}
            </Timestamp>
          </WinnerCard>
        ))}
      </WinnersList>
    </WinnersContainer>
  );
};
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #4ecca3;
  border-top: 4px solid #1a1a2e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px;
`;

const LoadingText = styled.p`
  font-size: 18px;
  color: #4ecca3;
`;
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
`;

const ErrorText = styled.h3`
  font-size: 24px;
  color: #ff6b6b;
  margin: 20px 0 10px;
`;

const ErrorSubText = styled.p`
  font-size: 16px;
  color: #a0a0a0;
`;

const WinnersContainer = styled.div`
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffd700;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FilterButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? "#4ecca3" : "#16213e")};
  color: ${(props) => (props.active ? "#1a1a2e" : "#ffffff")};
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? "#45b393" : "#1e2a4a")};
  }
`;

const WinnersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 665px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #16213e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4ecca3;
    border-radius: 4px;
  }
`;

const WinnerCard = styled.div<{ isCurrentUser: boolean }>`
  background-color: ${(props) => (props.isCurrentUser ? "#2a3f5f" : "#16213e")};
  border-radius: 8px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

const Username = styled.div`
  font-weight: bold;
  color: #4ecca3;
  grid-column: 1 / -1;
`;

const WinInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
`;

const BetAmount = styled.span`
  color: #e0e0e0;
`;

const Multiplier = styled.span`
  color: #ff6b6b;
  font-weight: bold;
`;

const WinAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #4ecca3;
`;

const Timestamp = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  text-align: right;
`;

export { RecentWinners };

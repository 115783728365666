import React, { useState } from "react";
import styled from "styled-components";
import { Login } from "./Login";
import { Register } from "./Register";

const LoginButton = styled.button`
  background: none;
  border: 2px solid #4ecca3;
  color: #4ecca3;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #4ecca3;
    color: #16213e;
  }
`;

const RegisterButton = styled.button`
  background: #4ecca3;
  color: #16213e;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: #45b393;
  }
`;

const AuthModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is above other elements */
`;

const AuthModalContent = styled.div`
  background-color: #16213e;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 400px;
`;

const AuthModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const AuthModalTitle = styled.h2`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
`;

const AuthModalCloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
`;

const AuthModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSwitch = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div>
      <LoginButton
        onClick={() => {
          handleToggle();
          setIsLogin(true);
        }}
      >
        Login
      </LoginButton>
      <RegisterButton
        onClick={() => {
          handleToggle();
          setIsLogin(false);
        }}
      >
        Register
      </RegisterButton>
      {isOpen && (
        <AuthModalContainer onClick={handleToggle}>
          <AuthModalContent onClick={(e) => e.stopPropagation()}>
            <AuthModalHeader>
              <AuthModalTitle>{isLogin ? "Login" : "Register"}</AuthModalTitle>
              <AuthModalCloseButton onClick={handleToggle}>
                &times;
              </AuthModalCloseButton>
            </AuthModalHeader>
            {isLogin ? (
              <Login handleSwitch={handleSwitch} />
            ) : (
              <Register handleSwitch={handleSwitch} />
            )}
          </AuthModalContent>
        </AuthModalContainer>
      )}
    </div>
  );
};

export { AuthModal };

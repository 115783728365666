// src/features/user/userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAttributes } from "../../types";
import { RootState } from "../store-config";
import { act } from "react";

interface UserState {
  user: UserAttributes | null;
  accessToken: string | null;
  isAuthenticated: boolean;
}

const initialState: UserState = {
  user: null,
  accessToken: null,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        user: UserAttributes;
        token: string;
        isAuthenticated: boolean;
      }>
    ) => {
      console.log("store user", action.payload.user);
      state.user = action.payload.user;
      state.accessToken = action.payload.token;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isAuthenticated = false; // Reset isAuthenticated when logging out
    },
    setAccessToken: (
      state,
      action: PayloadAction<{
        user: UserAttributes;
        accessToken: string;
      }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    updateUserWallet: (state, action: PayloadAction<number>) => {
      if (!state.user) {
        return;
      }
      state.user.wallet_balance = action.payload;
    },
  },
});

export const { setUser, logout, updateUserWallet, setAccessToken } =
  userSlice.actions;
export const selectUser = (state: RootState) => state.rootReducer.users.user;
export const selectIsAuthenticated = (state: RootState) =>
  state.rootReducer.users.isAuthenticated;

export default userSlice.reducer;

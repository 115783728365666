import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Plane } from "lucide-react";

interface StarProps {
  style: React.CSSProperties;
}

const PlaneIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.2.6-.6.5-1z" />
  </svg>
);

const Star: React.FC<StarProps> = ({ style }) => (
  <div
    style={{
      position: "absolute",
      width: "2px",
      height: "2px",
      backgroundColor: "white",
      borderRadius: "50%",
      ...style,
    }}
  />
);

interface StarStyle extends React.CSSProperties {
  animationDelay: string;
}

const LoadingScreen: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    const contentTimer = setTimeout(() => setShowContent(true), 500);

    const progressTimer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = Math.min(oldProgress + Math.random() * 5, 100);
        if (newProgress === 100) {
          clearInterval(progressTimer);
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(progressTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const stars: StarStyle[] = Array(100)
    .fill(null)
    .map(() => ({
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      animationDelay: `${Math.random() * 5}s`,
    }));

  return (
    <div className="relative h-screen w-full overflow-hidden bg-gradient-to-br from-indigo-900 via-blue-900 to-indigo-800">
      {/* Animated background elements */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMDAwMDAwMzAiPjwvcmVjdD4KPHBhdGggZD0iTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVoiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSIjMDAwMDAwNTAiPjwvcGF0aD4KPC9zdmc+')] opacity-20"></div>

      {/* Stars */}
      {stars.map((style, index) => (
        <Star key={index} style={style} />
      ))}

      {/* Moving clouds */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="cloud cloud1"></div>
        <div className="cloud cloud2"></div>
        <div className="cloud cloud3"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col items-center justify-center">
        <AnimatePresence>
          {showContent && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="text-center"
            >
              <motion.div
                animate={{
                  y: [0, -15, 0],
                  rotate: [-5, 5, -5],
                }}
                transition={{
                  y: { repeat: Infinity, duration: 2, ease: "easeInOut" },
                  rotate: { repeat: Infinity, duration: 4, ease: "easeInOut" },
                }}
                className="mb-8 relative"
              >
                <Plane size={80} className="text-green drop-shadow-lg" />
                <div className="text-white drop-shadow-lg">
                  <PlaneIcon />
                </div>
                <motion.div
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-16 h-1 bg-white/30 rounded-full"
                  animate={{
                    scaleX: [1, 1.5, 1],
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>

              <h1 className="text-5xl font-bold text-white mb-6 drop-shadow-lg">
                Your Game Name
              </h1>

              <div className="w-80 h-3 bg-white/30 rounded-full overflow-hidden mb-4">
                <motion.div
                  className="h-full bg-white"
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ type: "spring", stiffness: 50 }}
                />
              </div>

              <p className="text-white text-xl font-semibold mb-8">
                {Math.round(progress)}%
              </p>

              <motion.p
                className="text-white/90 text-lg font-medium"
                animate={{ opacity: [0.5, 1] }}
                transition={{
                  repeat: Infinity,
                  duration: 1.5,
                  repeatType: "reverse",
                }}
              >
                Preparing for an epic journeyy...
              </motion.p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Style for clouds and stars */}
      <style>{`
        @keyframes twinkle {
          0%, 100% { opacity: 0.5; }
          50% { opacity: 1; }
        }
        .Star {
          animation: twinkle 5s infinite;
        }
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        .cloud {
          position: absolute;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          animation: float 10s infinite;
        }
        .cloud1 {
          width: 300px;
          height: 100px;
          top: 20%;
          left: -10%;
          animation-duration: 11s;
        }
        .cloud2 {
          width: 200px;
          height: 70px;
          top: 50%;
          right: -5%;
          animation-duration: 9s;
          animation-delay: -3s;
        }
        .cloud3 {
          width: 250px;
          height: 80px;
          bottom: 10%;
          left: 20%;
          animation-duration: 13s;
          animation-delay: -7s;
        }
      `}</style>
    </div>
  );
};

export default LoadingScreen;
